import CartItem from '@vue-storefront/core/modules/cart/types/CartItem'
import productChecksum from './productChecksum'
import optimizeProduct from './optimizeProduct'

const readAssociated = product =>
  product.product_links.filter(p => p.link_type === 'associated').map(p => p.product)

const isDefined = product => typeof product !== 'undefined' || product !== null

const applyQty = product => ({
  ...product,
  qty: product.qty && typeof product.qty !== 'number' ? parseInt(product.qty) : product.qty
});

const applyChecksum = product => ({ ...product, checksum: productChecksum(product) })

function optimizeProductLink (link) {
  return {
    ...link,
    product: {
      price: link?.product?.price,
      price_incl_tax: link?.product?.price_incl_tax,
      priceInclTax: link?.product?.priceInclTax,
      qty: link?.product?.qty
    }
  }
}

function optimizeBundleProduct (product) {
  if (!product.bundle_options) return product
  const newBundleOptions = product?.bundle_options.map(option => {
    return {
      ...option,
      product_links: option?.product_links.map(optimizeProductLink)
    }
  })

  return {
    ...product,
    bundle_options: newBundleOptions
  }
}

const prepareProductsToAdd = (product: CartItem): CartItem[] => {
  const products = product.type_id === 'grouped' ? readAssociated(product) : [product]

  return products
    .filter(isDefined)
    .map(applyQty)
    .map(p => optimizeProduct(p))
    .map(optimizeBundleProduct)
    .map(applyChecksum);
};

export default prepareProductsToAdd;
